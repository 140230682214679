import {
    faChevronLeft,
    faChevronRight
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "gatsby";
import * as React from "react";
import { Col, Row } from "reactstrap";

interface PagerProps {
    currentPage: number;
    numPages: number;
    basePath: string;
}

const Pager: React.FunctionComponent<PagerProps> = ({
    currentPage,
    numPages,
    basePath
}) => {
    const previousIsEnabled = currentPage > 1;
    const nextIsEnabled = currentPage < numPages;

    const previousHref = previousIsEnabled
        ? currentPage === 2
            ? basePath
            : `${basePath}/${currentPage - 1}`
        : null;
    const previousClass = previousIsEnabled
        ? "btn btn-sm btn-secondary"
        : "btn btn-sm btn-secondary disabled";

    const nextHref = nextIsEnabled ? `${basePath}/${currentPage + 1}` : null;
    const nextClass = nextIsEnabled
        ? "btn btn-sm btn-secondary"
        : "btn btn-sm btn-secondary disabled";

    return (
        <Row>
            <Col>
                <Link
                    to={previousHref}
                    className={previousClass}
                    aria-disabled={!previousIsEnabled}
                >
                    <FontAwesomeIcon icon={faChevronLeft} className="mr-2" />
                    Previous
                </Link>
            </Col>

            <Col className="text-center text-muted">
                Page {currentPage} of {numPages}
            </Col>

            <Col className="text-right">
                <Link to={nextHref} className={nextClass}>
                    Next
                    <FontAwesomeIcon icon={faChevronRight} className="ml-2" />
                </Link>
            </Col>
        </Row>
    );
};

export default Pager;
