import * as React from "react";
import { graphql, Link } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PostingList from "../components/careers/postingList";
import ContentSection from "../components/contentSection";
import Pager from "../components/pager";
import {
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle
} from "reactstrap";

interface YamlNode {
    node: {
        path: string;
        title: string;
        locations?: string[];
        current: boolean;
        intro: string[];
    };
}

interface OpeningsProps {
    data: {
        thisPage: {
            edges: YamlNode[];
        };
        allPostings: {
            edges: YamlNode[];
        };
    };
    pathContext: {
        currentPage: number;
        numPages: number;
        current: boolean;
    };
}

const Openings: React.FunctionComponent<OpeningsProps> = ({
    data,
    pathContext: { currentPage, numPages, current }
}) => {
    const [dropdownOpen, setDropdownOpen] = React.useState(false);

    const toggle = () => setDropdownOpen(prevState => !prevState);

    return (
        <Layout>
            <SEO title="Job Openings" />

            <ContentSection
                title={`${current ? "Current" : "Previous"} Job Openings`}
            >
                <div className="my-3 d-flex justify-content-end align-items-center">
                    <Link
                        to={`/careers/openings${current ? "/previous" : ""}`}
                        className="btn btn-outline-secondary mx-2"
                    >
                        {current ? "Previous" : "Current"} Openings
                    </Link>

                    <Dropdown
                        isOpen={dropdownOpen}
                        toggle={toggle}
                        direction="down"
                    >
                        <DropdownToggle caret outline color="primary">
                            All Current Openings
                        </DropdownToggle>

                        <DropdownMenu>
                            {data.allPostings.edges.map((x, i) => (
                                <DropdownItem key={`dropdown-item-${i + 1}`}>
                                    <Link to={x.node.path}>{x.node.title}</Link>
                                </DropdownItem>
                            ))}
                        </DropdownMenu>
                    </Dropdown>
                </div>

                <Pager
                    currentPage={currentPage}
                    numPages={numPages}
                    basePath={`/careers/openings${current ? "" : "/previous"}`}
                />

                {data.thisPage.edges && data.thisPage.edges.length > 0 && (
                    <PostingList postings={data.thisPage.edges} />
                )}

                <Pager
                    currentPage={currentPage}
                    numPages={numPages}
                    basePath={`/careers/openings${current ? "" : "/previous"}`}
                />
            </ContentSection>
        </Layout>
    );
};

export default Openings;

export const postingListQuery = graphql`
    query postingListQuery($skip: Int!, $limit: Int!, $current: Boolean!) {
        thisPage: allJobPostingsYaml(
            limit: $limit
            skip: $skip
            sort: { fields: title }
            filter: { current: { eq: $current } }
        ) {
            edges {
                node {
                    path
                    title
                    locations
                    current
                    intro
                }
            }
        }
        allPostings: allJobPostingsYaml(
            sort: { fields: title }
            filter: { current: { eq: true } }
        ) {
            edges {
                node {
                    path
                    title
                    locations
                    current
                }
            }
        }
    }
`;
