import { Link } from "gatsby";
import * as React from "react";
import { Card, CardBody, CardFooter, CardText } from "reactstrap";
import ListCard from "../listCard";

interface PostingNode {
    node: {
        path: string;
        title: string;
        locations?: string[];
        intro: string[];
    };
}

interface PostingListProps {
    postings: PostingNode[];
}

const PostingList: React.FunctionComponent<PostingListProps> = ({
    postings
}) => (
    <React.Fragment>
        {postings.map(({ node: { path, title, locations, intro } }, i) => (
            <ListCard
                key={`posting-${i + 1}`}
                title={title}
                linkPath={path}
                excerpt={intro[0]}
                linkText="View Details"
                titleExtra={locations.join(" / ")}
            />
            // <Card className="my-3">
            //     <CardBody>
            //         <div className="d-flex justify-content-between align-items-start">
            //             <CardText className="h4">{title}</CardText>

            //             <div>{locations.join(" / ")}</div>
            //         </div>

            //         <CardText>{intro[0]}</CardText>
            //     </CardBody>

            //     <CardFooter>
            //         <Link to={path}>View Details</Link>
            //     </CardFooter>
            // </Card>
        ))}
    </React.Fragment>
);

export default PostingList;
